module.exports = [{
      plugin: require('/home/runner/work/website/website/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/runner/work/website/website/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1080},
    },{
      plugin: require('/home/runner/work/website/website/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-145918559-1","head":true,"anonymize":true,"respectDNT":false,"pageTransitionDelay":100},
    },{
      plugin: require('/home/runner/work/website/website/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
